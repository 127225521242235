import React from 'react';
import PropTypes from 'prop-types';
import Logotype from '@components/ui/logotype';
import ActionLink from '@components/ui/action-link';
import NavigationButton from '@components/ui/navigation-button';
import DesktopSession from '@components/domain/shared/auth/DesktopSession';
import styles from './desktop-navigation.module.scss';

const DesktopNav = (props) => {
  return (
    <div className={`${styles.container} ${props.className}`}>
      <div className={styles.content}>
        <section>
          <Logotype />
        </section>

        { !props.isNavLinksHidden &&
          <section className={styles.right}>
            <nav>
              <ul>
                <li><NavigationButton path="/cursos/" label="cursos" /></li>
                <li><NavigationButton path="/sobre-nos/" label="sobre nós" /></li>
              </ul>
            </nav>
            <div>
              <ActionLink path="/candidatura/" label="candidata-te" width="160px" type="dark" />
            </div>
            <div className={styles.vbar}/>
            <nav>
              <ul>
                <li><DesktopSession /></li>
              </ul>
            </nav>
          </section>
        }
      </div>
    </div>
  );
};

export default DesktopNav;

DesktopNav.defaultProps = {
  className: '',
  isNavLinksHidden: false,
};

DesktopNav.propTypes = {
  className: PropTypes.string,
  isNavLinksHidden: PropTypes.bool,
};
